import React from 'react';
import SEO from '../components/seo';
import { ResetCSS } from 'common/src/assets/css/style';
import { Link } from 'gatsby';

const Success = () => (
  <>
    <SEO title="Message Sent" />
    <ResetCSS />
    <div style={{margin: '0 auto', textAlign:'center'}}>
      <h1>Thank you for contacting us. We will respond shortly</h1>
      <Link to="/">
      <button style={{backgroundColor: '#1a73e8', color: 'white', padding: '15px 20px', width: 300, border: 'none'}} to="/" href="/">Back to home page</button>
      </Link>
    </div>
  </>
);

export default Success;
